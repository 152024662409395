import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"

import AwardImg from "../images/chillenium/award.jpg"
import LaserImg from "../images/chillenium/laser_on_projector.jpg"
import SetupImg from "../images/chillenium/setup.jpg"
import ThumbImg from "../images/project_thumbnails/chillenium.png"
import ImageGallery from "../components/ImageGallery"

const imgs = [
    {
        img: ThumbImg,
        alt: "Unity 3D View of Project",
    },
    {
        img: SetupImg,
        alt: "Real World setup of control system",
    },
    {
        img: LaserImg,
        alt: "Laser appears decently on projector output",
    },
    {
        img: AwardImg,
        alt: "Award: Best in Programming"
    }
]

const ChilleniumPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>Chillenium 2019</h1>
            <p>Chillenium is a gam-jam (hackathon focused on video games) at Texas A&amp;M. In 2019 I participated. Since I didn't have a team, I was teamed up with two random students; both also programmers. We didn't really feel like tackling the arts side of game development. Rather, we set our sights on doing something interesting on the programming side. What we came up with was a little crazy: a game where the input method was a laser pointer and computer vision.</p>
            <p>I don't really recall how we came to this idea, but I do know that we were confident in our skills and felt like it was a good scope for the project. One of my teammates had a projector which he brought and I ran off to find laser pointers at some store in College Station. A little while later we had everything we needed. We setup our projector, pointer a camera at the projected image, tinkered in OpenCV, and surprisingly, you could detect the laser dot even on the rather bright projected image. With that there wasn't much more to do. We skinned the game with a cat running around a house (only logical choice when your input is a laser pointer).</p>
            <p>Ok, it was more complicated than that but I don't really remember it. It was (and sill is) the longest I've ever stayed up; almost 36 hours straight, I barely made it home afterwards. But in the end the novel input system worked decently well. It was certainly not the prettiest game, but we went on to win the `Best in Programming` award which had a cash prize of a cool $50. Not worth the sleep loss and the rather rough interview the following Monday. Overall, 8/10 would probably do again.</p>
            <ImageGallery value={imgs}/>
            {/* TODO - find the video demo */}
            <p>A video demo exists somewhere on the internet; I will have to find it at a future date.</p>
            {FooterBar()}
        </div>
    )
}

export default ChilleniumPage